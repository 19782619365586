<template>
  <section class="invoice-preview-wrapper">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="orderData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching invoice data
      </h4>
      <div class="alert-body">
        No invoice found with this invoice id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-invoice-list'}"
        >
          Invoice List
        </b-link>
        for other invoices.
      </div>
    </b-alert>

    <b-row
      v-if="orderData"
      class="invoice-preview"
    >

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">

            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

              <!-- Header: Left Content -->
              <div>
                <div class="logo-wrapper">

                  <h3 class="text-primary invoice-logo">
                    Sipariş Detayı
                  </h3>
                </div>
                <p class="card-text mb-25">
                  {{ orderData.customer_name }}
                </p>
                <p class="card-text mb-25">
                  Sipariş Oluşturan:   {{ orderData.user_name }}
                </p>
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h4 class="invoice-title">
                  Sipariş No
                  <span class="invoice-number">#{{ orderData.order_number }}</span>
                </h4>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    Tarih:
                  </p>
                  <p class="invoice-date">
                    {{ $format_datetime(orderData.created_at) }}
                  </p>
                </div>

              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Invoice Client & Payment Details -->
          <b-card-body

            class="invoice-padding pt-0"
          >
            <b-row class="invoice-spacing">

              <!-- Col: Invoice To -->
              <b-col
                cols="12"
                xl="6"
                class="p-0"
              >
                <h6 class="mb-2">
                  Gönderim Adresi:
                </h6>
                <h6 class="mb-25">
                  {{ orderData.shipping_address }}
                </h6>
                <p class="card-text mb-25">
                  {{ orderData.shipping_city }}
                </p>
                <p class="card-text mb-25">
                  {{ orderData.shipping_postal_code }}
                </p>
              </b-col>

              <!-- Col: Payment Details -->
              <b-col
                xl="6"
                cols="12"
                class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
              >
                <div>
                  <h6 class="mb-2">
                    Ödeme Bilgileri:
                  </h6>

                  <table>
                    <tbody>
                      <tr>
                        <td class="pr-1">
                          Ödeme Türü
                        </td>
                        <td><span class="font-weight-bold">{{ orderData.payment_details.payment_type_name }}</span></td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          Banka Adı:
                        </td>
                        <td>{{ orderData.payment_details.bank_name }}</td>
                      </tr>
                      <tr v-if="orderData.payment_details.payment_type==1">
                        <td class="pr-1">
                          Kart Türü:
                        </td>
                        <td>{{ orderData.payment_details.bank_name }}</td>
                      </tr>
                      <tr v-if="orderData.payment_details.payment_type==1">
                        <td class="pr-1">
                          Taksit:
                        </td>
                        <td>{{ orderData.payment_details.installment }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          Ödeme Tutarı:
                        </td>
                        <td><span class="font-weight-bold">{{ orderData.payment_details.paid_amount }}</span></td>
                      </tr>

                    </tbody>
                  </table>

                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Invoice Description: Table -->
          <b-table-lite
            responsive
            :items="orderData.products"
            :fields="productFields"
          >
            <template #cell(tax)="data">
              <b-card-text class="font-weight-bold mb-25">
                %{{ data.item.tax_rate }}
              </b-card-text>

            </template>
            <template #cell(task_description)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ data.item.tax_rate }}
              </b-card-text>

            </template>
            <template #cell(sended_piece)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ data.item.sended_piece }}
              </b-card-text>

            </template>
            <template #cell(send_piece)="data">
              <b-form-group v-if="data.item.sended_piece!=data.item.piece && true==false">

                <b-input-group>
                  <b-form-input

                    v-model="data.item.send_piece"
                    size="sm"
                    type="number"
                    min="0"
                    placeholder="Gönderilecek Miktar"
                  />
                  <b-input-group-append>
                    <b-button
                      size="sm"
                      variant="outline-primary"
                    >
                      Gönderildi
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>

            </template>

          </b-table-lite>

          <!-- Invoice Description: Total -->
          <b-card-body class="invoice-padding pb-0">
            <b-row>

              <!-- Col: Sales Persion -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-0 mt-3"
                order="2"
                order-md="1"
              />

              <!-- Col: Total -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-6 d-flex justify-content-end"
                order="1"
                order-md="2"
              >
                <div
                  class="invoice-total-wrapper"
                  style="max-width:16rem;"
                >

                  <hr class="my-50">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Kdv Dahil Toplam:
                    </p>
                    <p class="invoice-total-amount">
                      {{ orderData.total_amount }} TL
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Note -->
          <b-card-body class="invoice-padding pt-0">
            <span class="font-weight-bold">Sipaiş Notu: </span>
            <span>{{ orderData.customer_note }}</span>
          </b-card-body>
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>

          <!-- Button: Send Invoice -->
          <b-form-group>
            <v-select
              v-model="orderData.status"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="$staticParams.orderStatusTypes"
              :reduce="val => val.id"
              label="name"
              :clearable="false"
            >
              <template #option="{ name, variant,id }">
                <b-badge :variant="$staticParams.getOrderStatusType(id).variant">
                  {{ $staticParams.getOrderStatusType(id).name }}
                </b-badge>
              </template>
              <template #selected-option="{ name, variant,id }">

                <b-badge :variant="$staticParams.getOrderStatusType(id).variant">
                  {{ $staticParams.getOrderStatusType(id).name }}
                </b-badge>
              </template>
            </v-select>
          </b-form-group>
          <b-form-select
            v-if="orderData.status==3"
            v-model="orderData.cargo_company"
            :options="$staticParams.cargoCompanies"
          />
          <b-form-group
            v-if="orderData.status==3"
            label="Kargo Takip Kodu"
            label-for="tracking_code"
          >
            <b-form-input
              id="tracking_code"
              v-model="orderData.tracking_code"
              placeholder="Takip Kodu Giriniz"
            />
          </b-form-group>
          <hr>
          <b-form-checkbox
            v-model="orderData.is_send_transaction_mail"
            :value="true"
            :unchecked-value="false"
          >
            İşlem değişiklikleri müşteriye mail gitsin
          </b-form-checkbox>
          <hr>

          <!-- Button: Add Payment -->
          <b-button
            v-b-toggle.sidebar-invoice-add-payment
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            class="mb-75"
            block
            @click="saveData"
          >
            Kaydet
          </b-button>
        </b-card>
      </b-col>
    </b-row>

  </section>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import {
  BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle, BBadge, BFormGroup, BFormCheckbox, BFormInput, BInputGroup, BInputGroupAppend, BFormSelect,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import orderStoreModule from './orderStoreModule'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BBadge,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BFormInput,
    BFormSelect,
    BCol,
    vSelect,
    BFormGroup,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,

  },
  data() {
    return {
      statusOptions: [
        {
          title: 'Aperture',
          icon: 'ApertureIcon',
        },
        {
          title: 'Codepen',
          icon: 'CodepenIcon',
        },
        {
          title: 'Globe ',
          icon: 'GlobeIcon',
        },
        {
          title: 'Instagram ',
          icon: 'InstagramIcon',
        },
      ],
    }
  },
  methods: {
    showSuccessMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarılı'), {
        title: this.$t('İşlem Başarılı'),
        variant: 'success',
        solid: false,
      })
    },
    showErrorMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarısız'), {
        title: this.$t('İşlem Başarısız'),
        variant: 'danger',
        solid: false,
      })
    },
    saveData() {
      const order = {
        status: this.orderData.status,
        id: this.orderData.id,
        cargo_company: this.orderData.cargo_company,
        tracking_code: this.orderData.tracking_code,
        is_send_transaction_mail: this.is_send_transaction_mail,
      }
      store.dispatch('app-order/updateOrder', order)
        .then(() => {
          this.showSuccessMessage()
        })
        .catch(() => {
          this.showErrorMessage()
        })
    },
  },
  setup() {
    const orderData = ref(null)
    const paymentDetails = ref({})

    // Invoice Description
    // ? Your real data will contain this information
    const productFields = [
      { label: 'Ürün', key: 'product_name' },
      { label: 'Birim Fiyatı', key: 'price' },
      { label: 'Miktar', key: 'piece' },
      { label: 'Birim', key: 'unit_name' },
      { label: 'KDV', key: 'tax_name' },
      { label: 'Toplam', key: 'total_price' },
      { label: 'Gönderilen', key: 'sended_piece' },
      { label: '', key: 'send_piece' },
    ]

    const INVOICE_APP_STORE_MODULE_NAME = 'app-order'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, orderStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-order/fetchSingleOrder', { id: router.currentRoute.params.id })
      .then(response => {
        orderData.value = response.data.data
      })
      .catch(error => {
        if (error.response.status === 404) {
          orderData.value = undefined
        }
      })

    const printInvoice = () => {
      window.print()
    }

    return {
      orderData,
      paymentDetails,
      productFields,
      printInvoice,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
